// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["BBNanE3Yg", "n4_YPZrgF", "vLfXTi9wb", "A8UZs0K8A", "B95IJL77a", "BYqd7XFMI", "V0QLmTKaP", "DFNyEOJlH", "qjdobueoa", "IQdgMTfJi"];

const serializationHash = "framer-Q0NCd"

const variantClassNames = {A8UZs0K8A: "framer-v-14vs94", B95IJL77a: "framer-v-12xt02j", BBNanE3Yg: "framer-v-1y7c7tq", BYqd7XFMI: "framer-v-7za8sl", DFNyEOJlH: "framer-v-1mno4z1", IQdgMTfJi: "framer-v-1lko67r", n4_YPZrgF: "framer-v-15og8fo", qjdobueoa: "framer-v-1j6m2fu", V0QLmTKaP: "framer-v-17u95ct", vLfXTi9wb: "framer-v-gy4yb6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {A8UZs0K8A: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}, default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "BYqd7XFMI", "Image 16:9": "A8UZs0K8A", "Image 3:2": "vLfXTi9wb", "Image 4:3": "n4_YPZrgF", "Image 4:5": "B95IJL77a", "Variant 10": "IQdgMTfJi", "Variant 7": "V0QLmTKaP", "Variant 8": "DFNyEOJlH", "Variant 9": "qjdobueoa", Default: "BBNanE3Yg"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "BBNanE3Yg", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BBNanE3Yg", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1y7c7tq", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"BBNanE3Yg"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({A8UZs0K8A: {"data-framer-name": "Image 16:9"}, B95IJL77a: {"data-framer-name": "Image 4:5"}, BYqd7XFMI: {"data-framer-name": "Image 1:1"}, DFNyEOJlH: {"data-framer-name": "Variant 8"}, IQdgMTfJi: {"data-framer-name": "Variant 10"}, n4_YPZrgF: {"data-framer-name": "Image 4:3"}, qjdobueoa: {"data-framer-name": "Variant 9"}, V0QLmTKaP: {"data-framer-name": "Variant 7"}, vLfXTi9wb: {"data-framer-name": "Image 3:2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-Q0NCd[data-border=\"true\"]::after, .framer-Q0NCd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Q0NCd.framer-178mom0, .framer-Q0NCd .framer-178mom0 { display: block; }", ".framer-Q0NCd.framer-1y7c7tq { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-Q0NCd.framer-v-15og8fo.framer-1y7c7tq { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-Q0NCd.framer-v-gy4yb6.framer-1y7c7tq { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-Q0NCd.framer-v-14vs94.framer-1y7c7tq { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-Q0NCd.framer-v-12xt02j.framer-1y7c7tq, .framer-Q0NCd.framer-v-1lko67r.framer-1y7c7tq { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-Q0NCd.framer-v-7za8sl.framer-1y7c7tq { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-Q0NCd.framer-v-17u95ct.framer-1y7c7tq { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-Q0NCd.framer-v-1mno4z1.framer-1y7c7tq { aspect-ratio: 0.46545454545454545 / 1; height: var(--framer-aspect-ratio-supported, 550px); }", ".framer-Q0NCd.framer-v-1j6m2fu.framer-1y7c7tq { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"n4_YPZrgF":{"layout":["fixed","fixed"]},"vLfXTi9wb":{"layout":["fixed","fixed"]},"A8UZs0K8A":{"layout":["fixed","fixed"]},"B95IJL77a":{"layout":["fixed","fixed"]},"BYqd7XFMI":{"layout":["fixed","fixed"]},"V0QLmTKaP":{"layout":["fixed","fixed"]},"DFNyEOJlH":{"layout":["fixed","fixed"]},"qjdobueoa":{"layout":["fixed","fixed"]},"IQdgMTfJi":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerR4XCwUJWp: React.ComponentType<Props> = withCSS(Component, css, "framer-Q0NCd") as typeof Component;
export default FramerR4XCwUJWp;

FramerR4XCwUJWp.displayName = "Image / Aspect Ratio Copy 6";

FramerR4XCwUJWp.defaultProps = {height: 256, width: 256};

addPropertyControls(FramerR4XCwUJWp, {variant: {options: ["BBNanE3Yg", "n4_YPZrgF", "vLfXTi9wb", "A8UZs0K8A", "B95IJL77a", "BYqd7XFMI", "V0QLmTKaP", "DFNyEOJlH", "qjdobueoa", "IQdgMTfJi"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9", "Variant 10"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerR4XCwUJWp, [])